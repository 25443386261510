import { save } from './save'

export const mutationOptions = ({
  body,
  bodyOnly,
  headers,
  method,
  params,
  url,
  ...options
} = {}) => {
  const mutationFn = variables =>
    save({
      body,
      bodyOnly,
      headers,
      method,
      params,
      url,
      ...variables,
    })

  return { mutationFn, ...options }
}
