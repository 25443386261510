import * as _ from 'modules/util'
import * as R from 'ramda'

export const zIndex = (list, element) =>
  _.thru(stackingContexts, R.prop(list), R.indexOf(element), R.inc)

const stackingContexts = {
  app: [
    'page',
    'page-super',
    'banners',
    'page-loader',
    'app-nav',
    'app-header',
    'flash-messages',
    'modal',
    'popup',
  ],
  // legacy...
  rootElements: [
    'page',
    'appHeader',
    'banners',
    'mobileMenuButton',
    'overlay',
    'modal',
    'flashMessages',
    'super',
  ],
  appHeaderElements: ['mainNav', 'scrollbar', 'userNav'],
  pageElements: ['page', 'content', 'selected-actions-menu'],
}
