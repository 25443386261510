import getCsrfToken from 'modules/request/get-csrf-token'
import { mutationOptions } from './mutation'
import { performRequest } from './perform-request'

const _delete = ({ headers: headersIn, ...options } = {}) => {
  const headers = {
    'X-CSRF-Token': getCsrfToken(),
    ...headersIn,
  }

  return performRequest({ headers, ...options, method: 'DELETE' })
}

export { _delete as delete }

export const deleteOptions = options => mutationOptions({ ...options, method: 'DELETE' })
