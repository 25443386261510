const fetch = async ({ body, headers, method = 'GET', url, ...options }) => {
  const request = { method, url }
  const response = await window
    .fetch(url, {
      body: body && typeof body !== 'string' ? JSON.stringify(body) : body,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
      },
      method,
      timeout: 35 * 1000,
      ...options,
    })
    .then(parseResponse)
    .catch(throwRequestError(request))

  return response.status < 400 ? response : throwResponseError({ request, response })
}

const parseResponse = async response => ({
  body:
    response.status === 204
      ? undefined
      : await response.json().catch(error => {
          console.error(error)
          return undefined
        }),
  headers: Object.fromEntries(response.headers.entries()),
  status: response.status,
  url: response.url,
})

const throwRequestError = request => originalError => {
  throw new Error(originalError.message, { cause: { request } })
}

const throwResponseError = ({ request, response }) => {
  throw new Error('fetch', { cause: { request, response } })
}

export default fetch
