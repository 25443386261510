import { useAny, useCreated, useDeleted, useUpdated } from 'modules/pusher'
import * as Request from 'modules/request'
import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

export const makeResource = (name, { endpoint: endpointIn, watchName: watchNameIn } = {}) => {
  const endpoint = endpointIn || `/v1/${_.snakeCase(name)}`

  const addMethodToOptionsBasedOnId = options => ({
    method: options?.id ? 'PATCH' : 'POST',
    ...options,
  })

  const addUrlToOptions = options => ({
    url: L.join('/', L.elems, [endpoint, options?.id]),
    ...options,
  })

  const addUrlToOptionsAndRemoveId = R.pipe(addUrlToOptions, R.dissoc('id'))
  const makeMethod = fn => R.pipe(addUrlToOptionsAndRemoveId, fn)

  const mutationOptions = makeMethod(Request.mutationOptions)
  const queryOptions = makeMethod(Request.queryOptions)
  const useInvalidateCache = makeMethod(Request.useInvalidateCache)

  const archiveOptions = optionsIn => {
    const options = addUrlToOptionsAndRemoveId(optionsIn)
    return postOptions({ ...options, url: `${options.url}/archive` })
  }

  const deleteOptions = makeMethod(Request.deleteOptions)
  const patchOptions = makeMethod(Request.patchOptions)
  const postOptions = makeMethod(Request.postOptions)
  const putOptions = makeMethod(Request.putOptions)
  const saveOptions = options => mutationOptions(addMethodToOptionsBasedOnId(options))

  const unarchiveOptions = optionsIn => {
    const options = addUrlToOptionsAndRemoveId(optionsIn)
    return postOptions({ ...options, url: `${options.url}/unarchive` })
  }

  const watchName = _.snakeCase(watchNameIn || _.singularize(name))
  const watch = {
    useAny: callback => useAny(watchName, callback),
    useCreated: callback => useCreated(watchName, callback),
    useDeleted: callback => useDeleted(watchName, callback),
    useUpdated: callback => useUpdated(watchName, callback),
  }

  return {
    archiveOptions,
    deleteOptions,
    patchOptions,
    postOptions,
    putOptions,
    queryOptions,
    saveOptions,
    unarchiveOptions,
    useInvalidateCache,
    watch,
  }
}
