import * as D from 'modules/document'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { useCallback, useRef } from 'react'

const focusableQuery = `
  a[href],
  button:not([disabled]),
  input:not([disabled]),
  select:not([disabled]),
  textarea:not([disabled]),
  *[tabindex],
  *[contenteditable]
`

const trapFocusIn = element => event => {
  const rootElement = element.current
  if (!rootElement) return

  const focusableElements = rootElement.querySelectorAll(focusableQuery)
  const firstFocusableElement = R.head(focusableElements)
  const lastFocusableElement = R.last(focusableElements)

  if (!lastFocusableElement) event.preventDefault()

  if (!event.shiftKey && event.target === lastFocusableElement) {
    event.preventDefault()
    firstFocusableElement.focus()
  }

  if (event.shiftKey && (event.target === firstFocusableElement || event.target === rootElement)) {
    event.preventDefault()
    lastFocusableElement.focus()
  }
}

export const useTrapFocus = ({ ref: refIn, ...options } = {}) => {
  const element = useRef()

  D.useKeyDownMatching({ key: 'Tab' }, trapFocusIn(element), options)

  const ref = _.useResolvedElement(
    useCallback(node => {
      element.current = node
    }, []),
    refIn
  )

  return ref
}
