import * as _ from 'modules/util'
import fetch from './fetch'
import { joinUrlWithParams } from './util'

export const performRequest = async ({ bodyOnly = true, params = {}, url: urlIn, ...options }) => {
  const url = joinUrlWithParams(urlIn, params)
  const request = { ...options, url }

  try {
    const rawResponse = await fetch({ ...request, body: _.snakifyKeys(request.body) })
    const response = camelizeBody(rawResponse)
    return bodyOnly ? response.body : { request, response }
  } catch (error) {
    if (error?.cause?.request) error.cause.request.body = request.body
    throw error
  }
}

const transformBody = transformFn => object =>
  Object.hasOwn(object, 'body') ? { ...object, body: transformFn(object.body) } : object

const camelizeBody = transformBody(_.camelizeKeys)
