import getCsrfToken from 'modules/request/get-csrf-token'
import { performRequest } from './perform-request'

export const save = ({ headers: headersIn, ...options } = {}) => {
  const headers = {
    'X-CSRF-Token': getCsrfToken(),
    ...headersIn,
  }

  return performRequest({ headers, ...options })
}
