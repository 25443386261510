import * as S from 'apps/cliniko/style'
import Transition from 'components/transition'
import * as _ from 'modules/util'
import { useEffect } from 'react'
import CloseButton from './close-button'
import Header from './header'
import Modal from './modal'

const Popover = ({
  children,
  className,
  dismissible = true,
  modalStyles,
  onClose,
  pageLock,
  title,
}) => {
  const isPastBreakpointLarge = S.useIsPastBreakpoint(826)

  const lockPage = () => {
    const page = document.querySelector('[data-js-page-container]')
    if (!page) return
    page.classList.add('margin-t0')
    page.classList.add('lg-show')
    page.classList.add('no-scroll')
    page.setAttribute('aria-hidden', true)
  }

  const unlockPage = () => {
    const page = document.querySelector('[data-js-page-container]')
    if (!page) return
    window.setTimeout(() => {
      page.classList.remove('margin-t0')
      page.classList.remove('lg-show')
      page.classList.remove('no-scroll')
      page.removeAttribute('aria-hidden')
    }, 10)
  }

  useEffect(() => {
    if (!pageLock) return
    lockPage()
    return unlockPage
  }, [pageLock])

  return (
    <Modal
      aria-labelledby={_.when(title, 'popover-header')}
      css={modalStyles}
      onDismiss={_.when(dismissible, onClose)}>
      <Transition
        css={popoverStyles}
        duration={200}
        enter={popoverEnterStyles({ isPastBreakpointLarge })}
        from={popoverFromStyles({ isPastBreakpointLarge })}>
        <div className={className} css={popoverInnerStyles} data-testid="popover">
          {_.when(title, <Header id="popover-header" title={title} />)}
          <div css={modalContentStyles}>{children}</div>
        </div>
      </Transition>
      {_.when(dismissible, <CloseButton onClick={onClose} />)}
    </Modal>
  )
}

const popoverStyles = {
  flex: '1 1 auto',
  minWidth: 0,
  minHeight: 0,

  [S.legacy.media.large]: {
    position: 'absolute',
    top: S.rem(50),
    left: 0,
    right: 0,
    zIndex: S.zIndex('rootElements', 'popover'),
    width: '84%',
    maxWidth: S.rem(570),
    margin: `0 auto`,
    paddingBottom: S.rem(50),
  },
}

const popoverFromStyles = ({ isPastBreakpointLarge }) => ({
  opacity: 0,
  transform: isPastBreakpointLarge ? 'scale(0.95)' : 'translateY(3%)',
})

const popoverEnterStyles = ({ isPastBreakpointLarge }) => ({
  opacity: 1,
  transform: isPastBreakpointLarge ? 'scale(1)' : 'translateY(0%)',
})

const popoverInnerStyles = {
  background: 'white',
  ...S.center,

  [S.legacy.media.large]: {
    marginBottom: S.rem(50),
  },
}

const modalContentStyles = {
  padding: S.rem(20),

  [S.legacy.media.medium]: {
    padding: S.rem(30),
  },
}

export default Popover
