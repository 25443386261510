import { flexWrap as flexWrapIn } from 'modules/style/helpers'
import { legacyGridValue as grid } from 'modules/style/space'
import { rem } from 'modules/style/units'

export const flexWrap = template => flexWrapIn(template, { grid })

export const buttonPadding = ({ compact } = {}) => ({
  paddingTop: rem(6),
  paddingBottom: rem(5),
  paddingLeft: rem(compact ? 10 : 20),
  paddingRight: rem(compact ? 10 : 20),
  minHeight: rem(35),
  lineHeight: rem(22),
})
